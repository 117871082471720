import React, { Component } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

// styles
import "../assets/styles/not-found/not-found.scss";


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    console.info("error", error);

    return { hasError: true, error };
  }

  componentDidCatch(error) {
    if (error.stack?.includes("ChunkLoadError") && !sessionStorage.getItem("ChunkLoadErrorReloaded")) {
      window.location.reload();

      sessionStorage.setItem("ChunkLoadErrorReloaded", "true");
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || (
        <div className="not-found--wrapper error-boundary">
          <div className="not-found--container">
            <h1 className="not-found--title">
              Sorry, something went wrong
            </h1>
            <div className="not-found--body">
              <p className="not-found--text">We are experiencing technical issues, please try again later</p>
            </div>
            <Link to="/" className="not-found--go-home-container">
              <Button
                size="large"
                className="not-found--go-home-button"
                type="ghost"
                onClick={() => window.location.reload()}
              >
                Try Again
              </Button>
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
