import { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const queryParams = {};

  for (const [key, value] of params) {
    queryParams[key] = value;
  }

  const setQueryParams = useCallback((obj, shouldReplace = false) => {
    const currentParams = new URLSearchParams(location.search);

    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        currentParams.set(key, obj[key]);
      } else {
        currentParams.delete(key);
      }
    });

    const newLocation = { search: `?${currentParams.toString()}` };
    if (shouldReplace) {
      history.replace(newLocation);
    } else {
      history.push(newLocation)
    }
  }, [location.pathname, location.search]);

  return [queryParams, setQueryParams];
}

export default useQueryParams;
