export const SCOTLAND_PROPERTIES = {
  country: "United Kingdom",
  country_code: "gb",
  state: "Scotland",
  county: "Scotland",
  url: "scotland",
  latitude: 56.4907,
  longitude: -4.2026,
  zoom: 5,
  radius: 309,
};

export const SCOTLAND_OPTIONS = [
  {
    county: "Aberdeen City",
    state: "Scotland",
    country_code: "gb",
    url: "city-of-aberdeen",
    latitude: 57.1497,
    longitude: -2.0943,
    zoom: 10,
    radius: 16,
  },
  {
    county: "Aberdeenshire",
    state: "Scotland",
    country_code: "gb",
    url: "aberdeenshire",
    latitude: 56.93299,
    longitude: -2.02972,
    zoom: 8,
    radius: 106,
  },
  {
    county: "Angus (Forfarshire)",
    state: "Scotland",
    country_code: "gb",
    url: "angus-forfarshire",
    latitude: 56.5731,
    longitude: -2.61063,
    zoom: 9,
    radius: 24,
  },
  {
    county: "Argyll",
    state: "Scotland",
    country_code: "gb",
    url: "argyll",
    latitude: 55.43525,
    longitude: -4.71863,
    zoom: 7,
    radius: 101,
  },
  {
    county: "Ayrshire",
    state: "Scotland",
    country_code: "gb",
    url: "ayrshire",
    latitude: 55.09409,
    longitude: -3.72162,
    zoom: 8,
    radius: 43,
  },
  {
    county: "Banffshire",
    state: "Scotland",
    country_code: "gb",
    url: "banffshire",
    latitude: 57.5371,
    longitude: -2.8207,
    zoom: 8,
    radius: 51,
  },
  {
    county: "Berwickshire",
    state: "Scotland",
    country_code: "gb",
    url: "berwickshire",
    latitude: 55.7702,
    longitude: -2.3214,
    zoom: 8,
    radius: 31,
  },
  {
    county: "Bute",
    state: "Scotland",
    country_code: "gb",
    url: "bute",
    latitude: 55.74953,
    longitude: -4.96513,
    zoom: 10,
    radius: 58,
  },
  {
    county: "Caithness",
    state: "Scotland",
    country_code: "gb",
    url: "caithness",
    latitude: 58.28495,
    longitude: -3.22861,
    zoom: 9,
    radius: 59,
  },
  {
    county: "Clackmannanshire",
    state: "Scotland",
    country_code: "gb",
    url: "clackmannanshire",
    latitude: 56.10919,
    longitude: -3.69381,
    zoom: 9,
    radius: 29,
  },
  {
    county: "Cromartyshire",
    state: "Scotland",
    country_code: "gb",
    url: "cromartyshire",
    latitude: 57.6766,
    longitude: -4.2416,
    zoom: 8,
    radius: 39,
  },
  {
    county: "Dumfriesshire",
    state: "Scotland",
    country_code: "gb",
    url: "dumfries-and-galloway",
    latitude: 55.1833,
    longitude: -3.6645,
    zoom: 8,
    radius: 47,
  },
  {
    county: "Dunbartonshire (Dumbarton)",
    state: "Scotland",
    country_code: "gb",
    url: "dunbartonshire-dumbarton",
    latitude: 55.90073,
    longitude: -4.35951,
    zoom: 10,
    radius: 18,
  },
  {
    county: "City of Dundee",
    state: "Scotland",
    country_code: "gb",
    url: "city-of-dundee",
    latitude: 56.45756,
    longitude: -2.9064,
    zoom: 8,
    radius: 14,
  },
  {
    county: "East Ayrshire",
    state: "Scotland",
    country_code: "gb",
    url: "east-ayrshire",
    latitude: 55.4519,
    longitude: -4.2744,
    zoom: 8,
    radius: 34,
  },
  {
    county: "East Dunbartonshire",
    state: "Scotland",
    country_code: "gb",
    url: "east-dunbartonshire",
    latitude: 55.9744,
    longitude: -4.2096,
    zoom: 8,
    radius: 17,
  },
  {
    county: "East Lothian (Haddingtonshire)",
    state: "Scotland",
    country_code: "gb",
    url: "east-lothian-haddingtonshire",
    latitude: 55.87608,
    longitude: -2.60307,
    zoom: 10,
    radius: 22,
  },
  {
    county: "East Renfrewshire",
    state: "Scotland",
    country_code: "gb",
    url: "east-renfrewshire",
    latitude: 55.7854,
    longitude: -4.3372,
    zoom: 8,
    radius: 14,
  },
  {
    county: "City of Edinburgh",
    state: "Scotland",
    country_code: "gb",
    url: "city-of-edinburgh",
    latitude: 55.8414,
    longitude: -3.13042,
    zoom: 10,
    radius: 17,
  },
  {
    county: "Falkirk",
    state: "Scotland",
    country_code: "gb",
    url: "falkirk",
    latitude: 56.0017,
    longitude: -3.7835,
    zoom: 8,
    radius: 19,
  },
  {
    county: "Fife",
    state: "Scotland",
    country_code: "gb",
    url: "fife",
    latitude: 56.05977,
    longitude: -2.88803,
    zoom: 9,
    radius: 36,
  },
  {
    county: "City of Glasgow",
    state: "Scotland",
    country_code: "gb",
    url: "city-of-glasgow",
    latitude: 55.81942,
    longitude: -4.19369,
    zoom: 9,
    radius: 19,
  },
  {
    county: "Highland",
    state: "Scotland",
    country_code: "gb",
    url: "highland",
    latitude: 57.7154,
    longitude: -4.6063,
    zoom: 8,
    radius: 92,
  },
  {
    county: "Inverclyde",
    state: "Scotland",
    country_code: "gb",
    url: "inverclyde",
    latitude: 55.8958,
    longitude: -4.6824,
    zoom: 8,
    radius: 10,
  },
  {
    county: "Inverness-shire",
    state: "Scotland",
    country_code: "gb",
    url: "inverness-shire",
    latitude: 57.1526,
    longitude: -4.7139,
    zoom: 8,
    radius: 109,
  },
  {
    county: "Highland Council",
    state: "Scotland",
    country_code: "gb",
    url: "highland-council",
    latitude: 57.3596139,
    longitude: -5.0992763,
    zoom: 8,
    radius: 109,
  },
  {
    county: "Kincardineshire",
    state: "Scotland",
    country_code: "gb",
    url: "kincardineshire",
    latitude: 56.9783,
    longitude: -2.4608,
    zoom: 8,
    radius: 34,
  },
  {
    county: "Kinross-shire",
    state: "Scotland",
    country_code: "gb",
    url: "kinross-shire",
    latitude: 56.2026,
    longitude: -3.4214,
    zoom: 8,
    radius: 7,
  },
  {
    county: "Kirkcudbrightshire",
    state: "Scotland",
    country_code: "gb",
    url: "kirkcudbrightshire",
    latitude: 54.9582,
    longitude: -4.0184,
    zoom: 8,
    radius: 41,
  },
  {
    county: "Lanarkshire",
    state: "Scotland",
    country_code: "gb",
    url: "lanarkshire",
    latitude: 55.50085,
    longitude: -3.14481,
    zoom: 8,
    radius: 61,
  },
  {
    county: "Midlothian (County of Edinburgh)",
    state: "Scotland",
    country_code: "gb",
    url: "midlothian",
    latitude: 55.74257,
    longitude: -2.94228,
    zoom: 10,
    radius: 34,
  },
  {
    county: "Moray (Elginshire)",
    state: "Scotland",
    country_code: "gb",
    url: "moray-elginshire",
    latitude: 57.6153,
    longitude: -3.2922,
    zoom: 8,
    radius: 35,
  },
  {
    county: "Nairnshire",
    state: "Scotland",
    country_code: "gb",
    url: "nairnshire",
    latitude: 57.5783,
    longitude: -3.8567,
    zoom: 8,
    radius: 18,
  },
  {
    county: "Na h-Eileanan Siar",
    state: "Scotland",
    country_code: "gb",
    url: "na-h-eileanan-siar",
    latitude: 57.8333,
    longitude: -7.0167,
    zoom: 8,
    radius: 110,
  },
  {
    county: "North Ayrshire",
    state: "Scotland",
    country_code: "gb",
    url: "north-ayrshire",
    latitude: 55.7354,
    longitude: -4.663,
    zoom: 8,
    radius: 30,
  },
  {
    county: "North Lanarkshire",
    state: "Scotland",
    country_code: "gb",
    url: "north-lanarkshire",
    latitude: 55.8667,
    longitude: -3.9667,
    zoom: 8,
    radius: 22,
  },
  {
    county: "Orkney",
    state: "Scotland",
    country_code: "gb",
    url: "orkney",
    latitude: 59.04116,
    longitude: -2.90318,
    zoom: 7,
    radius: 169,
  },
  {
    county: "Perth and Kinross",
    state: "Scotland",
    country_code: "gb",
    url: "perth-and-kinross",
    latitude: 56.7012,
    longitude: -3.6618,
    zoom: 8,
    radius: 61,
  },
  {
    county: "Peeblesshire",
    state: "Scotland",
    country_code: "gb",
    url: "peeblesshire",
    latitude: 55.6502,
    longitude: -3.1915,
    zoom: 8,
    radius: 29,
  },
  {
    county: "Perthshire",
    state: "Scotland",
    country_code: "gb",
    url: "perthshire",
    latitude: 56.7041,
    longitude: -3.7318,
    zoom: 8,
    radius: 61,
  },
  {
    county: "Renfrewshire",
    state: "Scotland",
    country_code: "gb",
    url: "renfrewshire",
    latitude: 55.84801,
    longitude: -4.56856,
    zoom: 10,
    radius: 16,
  },
  {
    county: "Ross and Cromarty",
    state: "Scotland",
    country_code: "gb",
    url: "ross-and-cromarty",
    latitude: 57.5793,
    longitude: -5.0987,
    zoom: 8,
    radius: 76,
  },
  {
    county: "Ross-shire",
    state: "Scotland",
    country_code: "gb",
    url: "ross-shire",
    latitude: 57.6081,
    longitude: -4.7325,
    zoom: 8,
    radius: 70,
  },
  {
    county: "Roxburghshire",
    state: "Scotland",
    country_code: "gb",
    url: "roxburghshire",
    latitude: 55.4787,
    longitude: -2.5506,
    zoom: 8,
    radius: 34,
  },
  {
    county: "Scottish Borders",
    state: "Scotland",
    country_code: "gb",
    url: "scottish-borders",
    latitude: 55.5486,
    longitude: -2.7863,
    zoom: 8,
    radius: 51,
  },
  {
    county: "Shetland Islands",
    state: "Scotland",
    country_code: "gb",
    url: "shetland-islands",
    latitude: 60.3305,
    longitude: -1.2286,
    zoom: 8,
    radius: 64,
  },
  {
    county: "South Ayrshire",
    state: "Scotland",
    country_code: "gb",
    url: "south-ayrshire",
    latitude: 55.3363,
    longitude: -4.6514,
    zoom: 8,
    radius: 41,
  },
  {
    county: "South Lanarkshire",
    state: "Scotland",
    country_code: "gb",
    url: "south-lanarkshire",
    latitude: 55.6073,
    longitude: -3.7801,
    zoom: 8,
    radius: 54,
  },
  {
    county: "Selkirkshire",
    state: "Scotland",
    country_code: "gb",
    url: "selkirkshire",
    latitude: 55.5491,
    longitude: -2.9054,
    zoom: 8,
    radius: 25,
  },
  {
    county: "Shetland (Zetland)",
    state: "Scotland",
    country_code: "gb",
    url: "shetland-zetland",
    latitude: 60.24829,
    longitude: -1.37657,
    zoom: 7,
    radius: 103,
  },
  {
    county: "Stirlingshire",
    state: "Scotland",
    country_code: "gb",
    url: "stirlingshire",
    latitude: 56.1177,
    longitude: -4.0576,
    zoom: 8,
    radius: 39,
  },
  {
    county: "Sutherland",
    state: "Scotland",
    country_code: "gb",
    url: "sutherland",
    latitude: 58.1715,
    longitude: -4.9168,
    zoom: 8,
    radius: 63,
  },
  {
    county: "West Dunbartonshire",
    state: "Scotland",
    country_code: "gb",
    url: "west-dunbartonshire",
    latitude: 55.9676,
    longitude: -4.5713,
    zoom: 8,
    radius: 21,
  },
  {
    county: "West Lothian (Linlithgowshire)",
    state: "Scotland",
    country_code: "gb",
    url: "west-lothian",
    latitude: 55.88829,
    longitude: -3.60894,
    zoom: 10,
    radius: 26,
  },
  {
    county: "Wigtownshire",
    state: "Scotland",
    country_code: "gb",
    url: "wigtownshire",
    latitude: 54.8415,
    longitude: -4.5704,
    zoom: 8,
    radius: 42,
  },
];
