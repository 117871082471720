import React from "react";
import { Spin } from "antd";

import "./page-fallback.scss";


const PageFallback = () => {
  return (
    <div className="page-fallback" onClick={() => null}>
      <Spin/>
    </div>
  );
};

export default PageFallback;
