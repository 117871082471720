import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";


const PrivateRoute = ({ component: Component, isAuthenticated, location, allowed, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!isAuthenticated) {
        if (location.pathname.includes("/profile/subscription")) {
          return <Redirect to={{ pathname: "/", search: "?redirect=subscription", state: { from: location } }}/>
        }

        return <Redirect to={{ pathname: "/", state: { from: location } }}/>
      }
      if (!allowed) {
        return null;
      }

      return <Component {...props} />;
    }}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  allowed: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    allowed: state.general.allowed,
  })
)(PrivateRoute);
