import * as types from "./itemsActionsTypes";


export const getItemDataRequest = (payload) => {
  return { type: types.GET_ITEM_DATA_REQUEST, payload };
};

export const getItemDataForPrivateRoutesRequest = (payload) => {
  return {
    type: types.GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST,
    payload,
  };
};

export const clearItemData = (payload) => {
  return { type: types.CLEAR_ITEM_DATA, payload };
};

export const getAllItemsRequest = (payload) => {
  return { type: types.GET_ALL_ITEMS_REQUEST, payload };
};

export const getAllItemsForPrivateRoutesRequest = (payload) => {
  return { type: types.GET_ALL_ITEMS_FOR_PRIVATE_ROUTES_REQUEST, payload };
};

export const sendEmailToDealerAboutItemRequest = (payload) => {
  return { type: types.SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST, payload };
};

export const sendEmailToDealerAboutDealerRequest = (payload) => {
  return { type: types.SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST, payload };
};

export const openSuccessModal = (payload) => {
  return { type: types.OPEN_SUCCESS_MODAL, payload };
};

export const closeSuccessModal = (payload) => {
  return { type: types.CLOSE_SUCCESS_MODAL, payload };
};

export const requestCallback = (ref, data) => ({
  type: types.CALLBACK_REQUEST,
  ref,
  data,
});

export const requestCallbackSuccess = (payload) => ({
  type: types.CALLBACK_REQUEST_SUCCESS,
  payload,
});

export const requestCallbackError = (payload) => ({
  type: types.CALLBACK_REQUEST_ERROR,
  payload,
});

export const callADealer = (ref, data) => ({
  type: types.CALL_A_DEALER_REQUEST,
  ref,
  data,
});

export const callADealerSuccess = (payload) => ({
  type: types.CALL_A_DEALER_SUCCESS,
  payload,
});

export const callADealerError = (payload) => ({
  type: types.CALL_A_DEALER_ERROR,
  payload,
});

export const callADealerReset = () => ({
  type: types.CALL_A_DEALER_RESET,
});

export const resetContactFormStatus = () => ({
  type: types.RESET_CONTACT_FORM_STATUS
});

export const clearItemDataForPrivateRoutes = () => ({
  type: types.CLEAR_ITEM_DATA_FOR_PRIVATE_ROUTES
});

export const editItemStatusRequest = (ref, params, status) => ({
  type: types.EDIT_ITEM_STATUS_REQUEST,
  ref,
  params,
  status
});

export const editItemStatusSuccess = (payload) => ({
  type: types.EDIT_ITEM_STATUS_REQUEST_SUCCESS,
  payload,
});

export const editItemStatusError = (payload) => ({
  type: types.EDIT_ITEM_STATUS_REQUEST_ERROR,
  payload,
});

export const searchAntiquesRequest = (payload) => ({
  type: types.SEARCH_ANTIQUES_REQUEST,
  payload,
});

export const updateUnregisteredFavouritesAction = (payload) => ({
  type: types.UPDATE_UNREGISTERED_FAVOURITES_ACTION,
  payload,
});

export const getUnregisteredFavouritesAction = () => ({
  type: types.GET_UNREGISTERED_FAVOURITES_ACTION,
});
