import * as types from "./itemsActionsTypes";
import { getUnregisteredFavourites, setUnregisteredFavourites } from "../../helpers";

export const initialState = {
  error: null,
  processing: false,
  initialLoading: false,
  itemData: {},
  itemDataForPrivateRoutes: {},
  items: [],
  itemsCount: 0,
  unregisteredFavourites: getUnregisteredFavourites(),
  openItemsSuccessModal: false,
  successModalText: "",
  isDealerMobileRequested: false,
  contactFormSubmittedSuccessfully: false,
};


const items = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADING:
      return {
        ...state,
        initialLoading: true,
      };

    case types.GET_ITEM_DATA_REQUEST: {
      return {
        ...state,
        error: null
      };
    }

    case types.HIDE_LOADING:
      return {
        ...state,
        initialLoading: false,
      };

    case types.TOGGLE_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };

    case types.OPEN_SUCCESS_MODAL:
      return {
        ...state,
        openItemsSuccessModal: true,
        successModalText: "Thank you! Your email has been successfully sent!",
      };

    case types.CLOSE_SUCCESS_MODAL:
      return {
        ...state,
        openItemsSuccessModal: false,
        successModalText: "",
      };

    case types.CLEAR_ITEM_DATA: {
      return {
        ...state,
        itemData: initialState.itemData
      };
    }

    case types.GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_SUCCESS:
      return {
        ...state,
        itemDataForPrivateRoutes: action.payload,
        error: null
      };

    case types.CLEAR_ITEM_DATA_FOR_PRIVATE_ROUTES:
      return {
        ...state,
        itemDataForPrivateRoutes: initialState.itemDataForPrivateRoutes,
      };

    case types.GET_ITEM_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        itemData: action.payload,
        error: null
      };

    case types.GET_ALL_ITEMS_REQUEST_SUCCESS:
    case types.SEARCH_ANTIQUES_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        itemsCount: action.payload.count,
        error: null
      };

    case types.SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_SUCCESS:
    case types.SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        processing: false,
        contactFormSubmittedSuccessfully: true,
      };

    case types.CALLBACK_REQUEST:
    case types.CALL_A_DEALER_REQUEST:
      return {
        ...state,
        error: null,
        initialLoading: true,
      };

    case types.CALLBACK_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        initialLoading: false,
        contactFormSubmittedSuccessfully: true,
      };

    case types.CALL_A_DEALER_SUCCESS:
      return {
        ...state,
        isDealerMobileRequested: true,
        initialLoading: false,
      };

    case types.CALL_A_DEALER_RESET:
      return {
        ...state,
        isDealerMobileRequested: false,
      };

    case types.GET_ITEM_DATA_REQUEST_ERROR:
    case types.GET_ALL_ITEMS_REQUEST_ERROR:
    case types.SEND_EMAIL_TO_DEALER_ABOUT_ITEM_REQUEST_ERROR:
    case types.SEND_EMAIL_TO_DEALER_ABOUT_DEALER_REQUEST_ERROR:
    case types.CALLBACK_REQUEST_ERROR:
    case types.CALL_A_DEALER_ERROR:
    case types.GET_ITEM_DATA_FOR_PRIVATE_ROUTES_REQUEST_ERROR:
    case types.EDIT_ITEM_STATUS_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        initialLoading: false,
      };

    case types.RESET_CONTACT_FORM_STATUS:
      return {
        ...state,
        contactFormSubmittedSuccessfully: false
      };

    case types.EDIT_ITEM_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        processing: false
      };

    case types.SEARCH_ANTIQUES_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
        processing: false,
      };

    case types.UPDATE_UNREGISTERED_FAVOURITES_ACTION:
      return {
        ...state,
        unregisteredFavourites: setUnregisteredFavourites(action.payload),
      };

    case types.GET_UNREGISTERED_FAVOURITES_ACTION:
      return {
        ...state,
        unregisteredFavourites: getUnregisteredFavourites(),
      };

    default:
      return state;
  }
};

export default items;
