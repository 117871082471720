export const NORTHERN_IRELAND_PROPERTIES = {
  country: "United Kingdom",
  country_code: "gb",
  state: "Northern Ireland",
  county: "Northern Ireland",
  url: "northern-ireland",
  latitude: 54.7877,
  longitude: -6.4923,
  zoom: 5,
  radius: 113,
};

export const NORTHERN_IRELAND_OPTIONS = [
  {
    county: "Antrim",
    state: "Northern Ireland",
    country_code: "gb",
    url: "antrim",
    latitude: 54.83234,
    longitude: -6.38306,
    zoom: 8,
    radius: 41,
  },
  {
    county: "Armagh",
    state: "Northern Ireland",
    country_code: "gb",
    url: "armagh",
    latitude: 54.3021,
    longitude: -6.58356,
    zoom: 9,
    radius: 48,
  },
  {
    county: "City of Belfast",
    state: "Northern Ireland",
    country_code: "gb",
    url: "city-of-belfast",
    latitude: 54.59639,
    longitude: -5.93018,
    zoom: 10,
    radius: 18,
  },
  {
    county: "Down",
    state: "Northern Ireland",
    country_code: "gb",
    url: "down",
    latitude: 54.36136,
    longitude: -5.91614,
    zoom: 8,
    radius: 32,
  },
  {
    county: "Fermanagh",
    state: "Northern Ireland",
    country_code: "gb",
    url: "fermanagh",
    latitude: 54.36296,
    longitude: -7.66022,
    zoom: 9,
    radius: 48,
  },
  {
    county: "Londonderry",
    state: "Northern Ireland",
    country_code: "gb",
    url: "londonderry",
    latitude: 54.997,
    longitude: -7.313,
    zoom: 9,
    radius: 48,
  },
  {
    county: "Derry and Strabane",
    state: "Northern Ireland",
    country_code: "gb",
    url: "derry-and-strabane",
    latitude: 54.7806842,
    longitude: -7.385386899999999,
    zoom: 9,
    radius: 48,
  },
  {
    county: "City of Derry",
    state: "Northern Ireland",
    country_code: "gb",
    url: "city-of-derry",
    latitude: 54.9966,
    longitude: -7.3086,
    zoom: 10,
    radius: 14,
  },
  {
    county: "Tyrone",
    state: "Northern Ireland",
    country_code: "gb",
    url: "tyrone",
    latitude: 54.6365,
    longitude: -7.16374,
    zoom: 9,
    radius: 47,
  },
];
