import React, { useMemo, useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";

// components
import AddToFavoriteModal from "../ItemPage/Modals/AddToFavoriteModal";

// icons
import FilledHeart from "../Icons/FilledHeart";
import GreenHeartIcon from "../Icons/GreenHeart";

// helpers
import { getUnregisteredFavourites } from "../../helpers/local-storage";

// redux
import { updateUnregisteredFavouritesAction } from "../../redux/items/itemsActions";
import {
  getFavoriteItemsIdsRequest,
  removeFavoriteItem as removeFavoriteItemRequest,
} from "../../redux/profiles/profilesActions";

const FavoriteItemButton = ({
  item,
  favItemsIds = [],
  isAuthenticated,
  unregisteredFavourites = [],
  updateUnregisteredFavourites,
  removeFavoriteItem,
  getFavoriteItemsIds,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isItemInFavourites, setIsItemInFavourites] = useState(false);

  const isFavorite = useMemo(() => {
    if (isAuthenticated) {
      return !!favItemsIds.find((favItemId) => favItemId === item.id);
    }

    return !!unregisteredFavourites.find(
      (favoriteItem) => favoriteItem.id === item.id
    );
  }, [favItemsIds, isAuthenticated, unregisteredFavourites]);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const removeItemFromFavourites = () => {
    removeFavoriteItem({ data: { id: item.id }, url: item.url });
  };

  const handleFavoritesClick = (e) => {
    e.stopPropagation();

    if (isAuthenticated) {
      isFavorite ? removeItemFromFavourites() : toggleIsOpen();

      return;
    }

    const newUnregisteredFavorites = isFavorite
      ? unregisteredFavourites.filter(
        (favoriteItem) => item.id !== favoriteItem.id
      )
      : unregisteredFavourites.concat(item);

    updateUnregisteredFavourites(newUnregisteredFavorites);

    message.success(
      `Item was ${isFavorite ? "removed from" : "added to"} Wish List`
    );
  };

  // effects
  useEffect(() => {
    setIsItemInFavourites(getUnregisteredFavourites().some(favItems => favItems.id === item.id));
  }, [JSON.stringify(unregisteredFavourites)]);

  // renders
  return (
    <>
      <div
        className={classNames("favorites-icon", className)}
        onClick={handleFavoritesClick}
      >
        {(isFavorite || isItemInFavourites) ? <FilledHeart /> : <GreenHeartIcon stroke="#FF0000" />}
      </div>

      {isAuthenticated && isOpen && (
        <AddToFavoriteModal
          isOpen={isOpen}
          onSuccess={getFavoriteItemsIds}
          onClose={toggleIsOpen}
          itemRef={item.ref}
        />
      )}
    </>
  );
};

FavoriteItemButton.propTypes = {
  item: PropTypes.object.isRequired,
  favItemsIds: PropTypes.array,
  getFavoriteItemsIds: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  unregisteredFavourites: PropTypes.array,
  updateUnregisteredFavourites: PropTypes.func.isRequired,
  removeFavoriteItem: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default connect(
  (state) => ({
    favItemsIds: state.profiles.favItemsIds,
    isAuthenticated: state.auth.isAuthenticated,
    unregisteredFavourites: state.items.unregisteredFavourites,
  }),
  {
    updateUnregisteredFavourites: updateUnregisteredFavouritesAction,
    removeFavoriteItem: removeFavoriteItemRequest,
    getFavoriteItemsIds: getFavoriteItemsIdsRequest,
  }
)(FavoriteItemButton);
