import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";

// constants
import { DIRECTORY_LINKS } from "../../../constants/directory";

const DirectorySection = () => {
  const slicedDirectoryLinks = DIRECTORY_LINKS.slice(0, 4);

  return (
    <section className="home--directory">
      <div className="home--directory-container page-container">
        <div className="home--directory-container--content">
          <h2 className="home--directory-container--heading">
            Search Best Antiques and Vintage Directory by Location
          </h2>
          <p className="home--directory-container--subheading">
            Search for the best antiques fairs, antiques shops, restoration
            specialists from across the UK. LoveAntiques.com brings together the
            whole antiques and vintage industry so you can find antiques and
            services near you.
          </p>
          <Link to="/directory">
            <Button size="large" className="home--directory-container--button">
              Search
            </Button>
          </Link>
        </div>
        <div className="home--directory-container--image-wrapper">
          <img
            className="home--directory-container--image"
            src={`${process.env.REACT_APP_IMAGES_URL}storefront_images/business/map-with-arrows.svg`}
            alt="map-with-arrows"
            loading="lazy"
          />
        </div>
        <div className="home--directory-container--list">
          {slicedDirectoryLinks.map((item, index) => {
            const isLastItem = index === slicedDirectoryLinks.length - 1;

            if (isLastItem) {
              return (
                <Link
                  to={{
                    pathname: "directory",
                    state: { scrollToCategories: true },
                  }}
                  key={item.key}
                >
                  <div className="home--directory-container--list-item">
                    <img
                      alt={item.title}
                      src={item.image}
                      className="home--directory-container--list-item--image"
                      loading="lazy"
                    />
                    <span className="home--directory-container--list-item--text">
                      More options
                    </span>
                  </div>
                </Link>
              );
            }

            return (
              <Link to={item.link} key={item.key}>
                <div className="home--directory-container--list-item">
                  <img
                    alt={item.title}
                    src={item.image}
                    className="home--directory-container--list-item--image"
                    loading="lazy"
                  />
                  <span className="home--directory-container--list-item--text">
                    {item.title}
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default DirectorySection;
