export const ENGLAND_PROPERTIES = {
  country: "United Kingdom",
  country_code: "gb",
  state: "England",
  county: "England",
  url: "england",
  latitude: 52.3555,
  longitude: -1.1743,
  zoom: 5,
  radius: 349,
};

export const ENGLAND_OPTIONS = [
  {
    county: "Avon",
    state: "England",
    country_code: "gb",
    url: "avon",
    latitude: 51.64915,
    longitude: -2.0352,
    zoom: 8,
    radius: 64,
  },
  {
    county: "Bedfordshire",
    state: "England",
    country_code: "gb",
    url: "bedfordshire",
    latitude: 52.59107,
    longitude: -0.312472,
    zoom: 10,
    radius: 20,
  },
  {
    county: "Berkshire",
    state: "England",
    country_code: "gb",
    url: "berkshire",
    latitude: 51.45356,
    longitude: -1.03907,
    zoom: 9,
    radius: 52,
  },
  {
    county: "City of Brighton and Hove",
    state: "England",
    country_code: "gb",
    url: "city-of-brighton-and-hove",
    latitude: 50.8459,
    longitude: -0.130147,
    zoom: 11,
    radius: 11,
  },
  {
    county: "City of Bristol",
    state: "England",
    country_code: "gb",
    url: "city-of-bristol",
    latitude: 51.46814,
    longitude: -2.63981,
    zoom: 10,
    radius: 29,
  },
  {
    county: "Buckinghamshire",
    state: "England",
    country_code: "gb",
    url: "buckinghamshire",
    latitude: 51.79,
    longitude: -0.75,
    zoom: 9,
    radius: 40,
  },
  {
    county: "Cambridgeshire",
    state: "England",
    country_code: "gb",
    url: "cambridgeshire",
    latitude: 52.3756,
    longitude: 0.00549,
    zoom: 8,
    radius: 38,
  },
  {
    county: "Cheshire",
    state: "England",
    country_code: "gb",
    url: "cheshire",
    latitude: 52.9933,
    longitude: -2.36893,
    zoom: 9,
    radius: 38,
  },
  {
    county: "Cornwall",
    state: "England",
    country_code: "gb",
    url: "cornwall",
    latitude: 49.81318,
    longitude: -4.7049,
    zoom: 8,
    radius: 120,
  },
  {
    county: "Cumberland",
    state: "England",
    country_code: "gb",
    url: "cumberland",
    latitude: 54.18816,
    longitude: -2.83173,
    zoom: 8,
    radius: 38,
  },
  {
    county: "Cumbria",
    state: "England",
    country_code: "gb",
    url: "cumbria",
    latitude: 54.12382,
    longitude: -2.55157,
    zoom: 8,
    radius: 38,
  },
  {
    county: "Derbyshire",
    state: "England",
    country_code: "gb",
    url: "derbyshire",
    latitude: 52.5897,
    longitude: -1.20026,
    zoom: 8,
    radius: 32,
  },
  {
    county: "Devon",
    state: "England",
    country_code: "gb",
    url: "devon",
    latitude: 50.7141,
    longitude: -3.5333,
    zoom: 8,
    radius: 48,
  },
  {
    county: "Dorset",
    state: "England",
    country_code: "gb",
    url: "dorset",
    latitude: 50.79726,
    longitude: -2.30438,
    zoom: 8,
    radius: 38,
  },
  {
    county: "Durham",
    state: "England",
    country_code: "gb",
    url: "durham",
    latitude: 54.68653,
    longitude: -1.79901,
    zoom: 9,
    radius: 55,
  },
  {
    county: "County Durham",
    state: "England",
    country_code: "gb",
    url: "county-durham",
    latitude: 54.68653,
    longitude: -1.79901,
    zoom: 9,
    radius: 55,
  },
  {
    county: "East Suffolk",
    state: "England",
    country_code: "gb",
    url: "east-suffolk",
    latitude: 52.24207,
    longitude: 1.46336,
    zoom: 9,
    radius: 71,
  },
  {
    county: "East Sussex",
    state: "England",
    country_code: "gb",
    url: "east-sussex",
    latitude: 50.94091,
    longitude: 0.31139,
    zoom: 9,
    radius: 24,
  },
  {
    county: "Essex",
    state: "England",
    country_code: "gb",
    url: "essex",
    latitude: 51.77172,
    longitude: 0.63927,
    zoom: 9,
    radius: 32,
  },
  {
    county: "Gloucestershire",
    state: "England",
    country_code: "gb",
    url: "gloucestershire",
    latitude: 51.76444,
    longitude: -2.16431,
    zoom: 8,
    radius: 34,
  },
  {
    county: "Greater London",
    state: "England",
    country_code: "gb",
    url: "greater-london",
    latitude: 51.48993,
    longitude: -0.08789,
    zoom: 9,
    radius: 61,
  },
  {
    county: "Greater Manchester",
    state: "England",
    country_code: "gb",
    url: "greater-manchester",
    latitude: 53.5069,
    longitude: -2.32007,
    zoom: 8,
    radius: 69,
  },
  {
    county: "Hampshire",
    state: "England",
    country_code: "gb",
    url: "hampshire",
    latitude: 51.04607,
    longitude: -1.34331,
    zoom: 8,
    radius: 41,
  },
  {
    county: "Herefordshire",
    state: "England",
    country_code: "gb",
    url: "herefordshire",
    latitude: 52.11162,
    longitude: -2.73994,
    zoom: 9,
    radius: 32,
  },
  {
    county: "Hertfordshire",
    state: "England",
    country_code: "gb",
    url: "hertfordshire",
    latitude: 51.7943,
    longitude: -0.3205,
    zoom: 8,
    radius: 45,
  },
  {
    county: "Humberside",
    state: "England",
    country_code: "gb",
    url: "humberside",
    latitude: 53.7002,
    longitude: -0.4072,
    zoom: 8,
    radius: 47,
  },
  {
    county: "Huntingdon and Peterborough",
    state: "England",
    country_code: "gb",
    url: "huntingdon-and-peterborough",
    latitude: 52.34253,
    longitude: -0.18644,
    zoom: 13,
    radius: 13,
  },
  {
    county: "Huntingdonshire",
    state: "England",
    country_code: "gb",
    url: "huntingdonshire",
    latitude: 52.37152,
    longitude: -0.22428,
    zoom: 9,
    radius: 40,
  },
  {
    county: "Isle of Ely",
    state: "England",
    country_code: "gb",
    url: "isle-of-ely",
    latitude: 52.399,
    longitude: 0.2621,
    zoom: 8,
    radius: 12,
  },
  {
    county: "Isle of Wight",
    state: "England",
    country_code: "gb",
    url: "isle-of-wight",
    latitude: 50.67122,
    longitude: -1.32726,
    zoom: 10,
    radius: 16,
  },
  {
    county: "Kent",
    state: "England",
    country_code: "gb",
    url: "kent",
    latitude: 51.20803,
    longitude: 0.74265,
    zoom: 9,
    radius: 64,
  },
  {
    county: "Lancashire",
    state: "England",
    country_code: "gb",
    url: "lancashire",
    latitude: 53.86287,
    longitude: -2.56481,
    zoom: 9,
    radius: 32,
  },
  {
    county: "Leicestershire",
    state: "England",
    country_code: "gb",
    url: "leicestershire",
    latitude: 52.6859,
    longitude: -1.13083,
    zoom: 9,
    radius: 50,
  },
  {
    county: "Lincolnshire",
    state: "England",
    country_code: "gb",
    url: "lincolnshire",
    latitude: 53.18577,
    longitude: -0.29602,
    zoom: 8,
    radius: 76,
  },
  {
    county: "Lincolnshire, Parts of Holland",
    state: "England",
    country_code: "gb",
    url: "lincolnshire-parts-of-holland",
    latitude: 52.85906,
    longitude: -0.003,
    zoom: 9,
    radius: 24,
  },
  {
    county: "Lincolnshire, Parts of Kesteven",
    state: "England",
    country_code: "gb",
    url: "lincolnshire-parts-of-kesteven",
    latitude: 52.95029,
    longitude: -0.49713,
    zoom: 8,
    radius: 24,
  },
  {
    county: "Lincolnshire, Parts of Lindsey",
    state: "England",
    country_code: "gb",
    url: "lincolnshire-parts-of-lindsey",
    latitude: 53.36367,
    longitude: -0.30212,
    zoom: 8,
    radius: 24,
  },
  {
    county: "London",
    state: "England",
    country_code: "gb",
    url: "london",
    latitude: 51.48914,
    longitude: -0.08789,
    zoom: 9,
    radius: 32,
  },
  {
    county: "Merseyside",
    state: "England",
    country_code: "gb",
    url: "merseyside",
    latitude: 53.49588,
    longitude: -2.92025,
    zoom: 9,
    radius: 29,
  },
  {
    county: "Middlesex",
    state: "England",
    country_code: "gb",
    url: "middlesex",
    latitude: 51.54637,
    longitude: -0.26283,
    zoom: 10,
    radius: 21,
  },
  {
    county: "Norfolk",
    state: "England",
    country_code: "gb",
    url: "norfolk",
    latitude: 52.67465,
    longitude: 0.9497,
    zoom: 8,
    radius: 100,
  },
  {
    county: "Northamptonshire",
    state: "England",
    country_code: "gb",
    url: "northamptonshire",
    latitude: 52.31184,
    longitude: -0.83771,
    zoom: 9,
    radius: 41,
  },
  {
    county: "Northumberland",
    state: "England",
    country_code: "gb",
    url: "northumberland",
    latitude: 55.30035,
    longitude: -2.07484,
    zoom: 8,
    radius: 67,
  },
  {
    county: "North Humberside",
    state: "England",
    country_code: "gb",
    url: "north-humberside",
    latitude: 53.7175,
    longitude: -0.3875,
    zoom: 8,
    radius: 38,
  },
  {
    county: "North Yorkshire",
    state: "England",
    country_code: "gb",
    url: "north-yorkshire",
    latitude: 54.1367,
    longitude: -1.38977,
    zoom: 8,
    radius: 83,
  },
  {
    county: "Nottinghamshire",
    state: "England",
    country_code: "gb",
    url: "nottinghamshire",
    latitude: 53.14677,
    longitude: -1.00525,
    zoom: 8,
    radius: 68,
  },
  {
    county: "Oxfordshire",
    state: "England",
    country_code: "gb",
    url: "oxfordshire",
    latitude: 51.81541,
    longitude: -1.29639,
    zoom: 8,
    radius: 34,
  },
  {
    county: "Soke of Peterborough",
    state: "England",
    country_code: "gb",
    url: "soke-of-peterborough",
    latitude: 52.573,
    longitude: -0.2445,
    zoom: 8,
    radius: 13,
  },
  {
    county: "Rutland",
    state: "England",
    country_code: "gb",
    url: "rutland",
    latitude: 52.64223,
    longitude: -0.62485,
    zoom: 10,
    radius: 20,
  },
  {
    county: "Shropshire",
    state: "England",
    country_code: "gb",
    url: "shropshire",
    latitude: 52.6537,
    longitude: -2.73422,
    zoom: 8,
    radius: 36,
  },
  {
    county: "Somerset",
    state: "England",
    country_code: "gb",
    url: "somerset",
    latitude: 51.07765,
    longitude: -3.04321,
    zoom: 9,
    radius: 38,
  },
  {
    county: "South Humberside",
    state: "England",
    country_code: "gb",
    url: "south-humberside",
    latitude: 53.6101,
    longitude: -0.5223,
    zoom: 8,
    radius: 34,
  },
  {
    county: "South Yorkshire",
    state: "England",
    country_code: "gb",
    url: "south-yorkshire",
    latitude: 53.48176,
    longitude: -1.34396,
    zoom: 9,
    radius: 82,
  },
  {
    county: "Staffordshire",
    state: "England",
    country_code: "gb",
    url: "staffordshire",
    latitude: 52.82659,
    longitude: -2.02807,
    zoom: 8,
    radius: 19,
  },
  {
    county: "Suffolk",
    state: "England",
    country_code: "gb",
    url: "suffolk",
    latitude: 52.24126,
    longitude: 1.05469,
    zoom: 9,
    radius: 51,
  },
  {
    county: "Surrey",
    state: "England",
    country_code: "gb",
    url: "surrey",
    latitude: 51.27197,
    longitude: -0.39536,
    zoom: 9,
    radius: 24,
  },
  {
    county: "Sussex",
    state: "England",
    country_code: "gb",
    url: "sussex",
    latitude: 50.94458,
    longitude: -0.04395,
    zoom: 9,
    radius: 27,
  },
  {
    county: "Tyne and Wear",
    state: "England",
    country_code: "gb",
    url: "tyne-and-wear",
    latitude: 54.93977,
    longitude: -1.59851,
    zoom: 8,
    radius: 47,
  },
  {
    county: "Warwickshire",
    state: "England",
    country_code: "gb",
    url: "warwickshire",
    latitude: 52.32283,
    longitude: -1.56707,
    zoom: 8,
    radius: 37,
  },
  {
    county: "West Midlands",
    state: "England",
    country_code: "gb",
    url: "west-midlands",
    latitude: 52.5055,
    longitude: -1.8154,
    zoom: 8,
    radius: 50,
  },
  {
    county: "Westmorland",
    state: "England",
    country_code: "gb",
    url: "westmorland",
    latitude: 54.45006,
    longitude: -2.74191,
    zoom: 8,
    radius: 35,
  },
  {
    county: "West Suffolk",
    state: "England",
    country_code: "gb",
    url: "west-suffolk",
    latitude: 52.25862,
    longitude: 0.65371,
    zoom: 9,
    radius: 16,
  },
  {
    county: "West Sussex",
    state: "England",
    country_code: "gb",
    url: "west-sussex",
    latitude: 50.94507,
    longitude: -0.45653,
    zoom: 9,
    radius: 27,
  },
  {
    county: "West Yorkshire",
    state: "England",
    country_code: "gb",
    url: "west-yorkshire",
    latitude: 53.74203,
    longitude: -1.68605,
    zoom: 9,
    radius: 24,
  },
  {
    county: "Wiltshire",
    state: "England",
    country_code: "gb",
    url: "wiltshire",
    latitude: 51.32563,
    longitude: -1.92566,
    zoom: 8,
    radius: 35,
  },
  {
    county: "Worcestershire",
    state: "England",
    country_code: "gb",
    url: "worcestershire",
    latitude: 52.2116,
    longitude: -2.21031,
    zoom: 9,
    radius: 38,
  },
  {
    county: "Yorkshire",
    state: "England",
    country_code: "gb",
    url: "yorkshire",
    latitude: 53.74203,
    longitude: -1.686,
    zoom: 9,
    radius: 97,
  },
  {
    county: "Yorkshire, East Riding",
    state: "England",
    country_code: "gb",
    url: "yorkshire-east-riding",
    latitude: 53.8373,
    longitude: -0.4902,
    zoom: 8,
    radius: 59,
  },
  {
    county: "Yorkshire, North Riding",
    state: "England",
    country_code: "gb",
    url: "yorkshire-north-riding",
    latitude: 54.3028,
    longitude: -1.2267,
    zoom: 8,
    radius: 29,
  },
  {
    county: "Yorkshire, West Riding",
    state: "England",
    country_code: "gb",
    url: "yorkshire-west-riding",
    latitude: 53.9946,
    longitude: -1.7689,
    zoom: 8,
    radius: 29,
  },
];
