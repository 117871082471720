import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const TopBarLink = ({
  category,
  setSelectedTopBarCategory,
}) => {
  return (
    <div className="header--top-bar-category-container">
      {["All Antiques"].includes(category.title) ? (
        <NavLink
          to={category.url}
          className="header--all-antiques-link"
          onMouseEnter={() => setSelectedTopBarCategory(category)}
        >
          {category.title}
        </NavLink>
      ) : (
        <a
          href={category.url}
          className="header--link"
          onClick={e => e.preventDefault()}
          onMouseEnter={() => setSelectedTopBarCategory(category)}
        >
          {category.key === "directory-and-more" ? <p>Directory and more <span className="header--link--new">NEW</span></p> : category.title}
        </a>
      )}
    </div>
  )
};

TopBarLink.propTypes = {
  category: PropTypes.object.isRequired,
  setSelectedTopBarCategory: PropTypes.func.isRequired,
};

export default TopBarLink;
